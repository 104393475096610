import React from 'react'

const CommonHero = ({ pageTitle, pageDesc, custombackground }) => (
  <div className="page-header">
    <div
      className="page-banner"
      style={{
        width: `100%`,
        height: `100%`,
        background: `url(${
          custombackground
            ? custombackground
            : 'https://res.cloudinary.com/brian-boals/image/upload/v1555484368/brianboals/slide11.jpg'
        })`,
      }}
    />
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-xl-11 mx-auto">
          <div className="text-center hero-content align-self-center">
            <h1 className="text-white align-self-center">{pageTitle}</h1>
            <p className="text-white">{pageDesc}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default CommonHero
