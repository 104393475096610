import React from 'react'
// import { Link } from 'gatsby'
import Layout from '../layouts'
import './index.css'
import OldNE from '../images/fyling-horse.jpg'
import CommonHero from '../components/commonhero/commonhero.js'

class IndexPage extends React.Component {
  render() {
    return (
      <Layout head={{ title: 'Downtown Colorado Springs' }}>
        <CommonHero
          pageTitle="Downtown Colorado Springs"
          custombackground="https://res.cloudinary.com/brian-boals/image/upload/v1562725074/brianboals/downtown-cs.jpg"
        />
        <main className="bg-white p-relative">
          <div className="container py-5">
            <p className="text-center">
              If you love old and charming, this area is for you! Close to
              shopping, plays, concerts, restaurants and the excitement of
              downtown living.
            </p>
          </div>
          <section className="py-5">
            <div className="container">
              <div className="intro text-center mb-5">
                <h2>Neighborhoods</h2>
              </div>
              <div className="row">
                <div className="col-sm-6 col-lg-4 p-1">
                  <div
                    className="area"
                    style={{
                      background: `url(${OldNE})`,
                    }}
                  >
                    <div className="area-content">
                      <h4 className="text-white">
                        <a
                          href="https://brianboals-oldnorthend.flymycommunity.com/"
                          target="_blank"
                          className="text-white"
                          rel="noopener noreferrer"
                          title="Old North End"
                        >
                          Old North End
                        </a>
                      </h4>
                      <p>
                        Historical Old North End is overflowing with charm with
                        some of the first and finest mansions in the area and
                        tall mature trees.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </Layout>
    )
  }
}

export default IndexPage
